import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarDataService } from 'src/app/services/sidebar-data.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material';
import { CategoriesService } from 'src/app/services/categories.service';
import { ToastrService } from 'ngx-toastr';
import { Category, CategoryProperty, ValueRecord } from 'src/app/interfaces';
import { PromptPopupComponent } from 'src/app/dialogs/prompt-popup/prompt-popup.component';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { isItemNotInArray, logger } from 'src/app/global-functions';
import * as uuid from 'uuid';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesComponent implements OnInit {
  activeSidebarSection = 'Products';
  isLoadingResults = false;
  canChangeOrder = false;
  allCategories = [];
  categoriesData = [];
  categoriesDataOrders = [];

  constructor(
    private sidebarData: SidebarDataService,
    private categoriesService: CategoriesService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoadingResults = true;
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
    this.categoriesService.getCategoriesOrders().subscribe(
      response => {
        this.categoriesDataOrders = response.body;
        this.categoriesService.setSize(999);
        this.getCategoriesData();
      },
      error => {
        // console.warn('getCategoriesOrders err ->', error); // example
        this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
        this.isLoadingResults = false;
      }
    );
  }

  getCategoriesData() {
    this.isLoadingResults = true;
    let promise = new Promise((resolve, reject) => {
      this.categoriesService.getCategories().subscribe(
        response => {
          this.isLoadingResults = false;
          this.categoriesData = response.body.data.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
          resolve();
        },
        error => {
          // console.warn('getCategoriesData err ->', error); // example
          this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
        }
      );
    });
    return promise;
  }

  getCategory(category: Category) {
    // console.warn('category', category);
    if (!category.properties) {
      this.categoriesService.getCategory(category.id).subscribe(
        data => {
          let categoryInArray = this.categoriesData.find(el => el.id === category.id);
          categoryInArray['properties'] = data.body.properties.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
          categoryInArray['isEdited'] = false;
        },
        error => {}
      );
    }
  }

  changeCategoryOrder(event: CdkDragDrop<string[]>) {
    this.isLoadingResults = true;

    let movedItems = {
      categoryOrders: {}
    };

    moveItemInArray(this.categoriesData, event.previousIndex, event.currentIndex);

    this.categoriesDataOrders.forEach(item => {
      // if (this.categoriesData[item.order]) {
      //   if (this.categoriesData[item.order].id !== item.id) {
      //     movedItems.categoryOrders[item.id] = this.categoriesData.findIndex(el => el.id === item.id);
      //   }
      // } else {
      // }
      movedItems.categoryOrders[item.id] = this.categoriesData.findIndex(el => el.id === item.id);
    });

    this.categoriesService.setCategoriesOrders(movedItems).subscribe(() => {
      this.isLoadingResults = false;
    });
  }

  addNewCategory() {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      panelClass: 'panel-prompt',
      data: {
        title: 'Create new category',
        message: 'Please input category name',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Create'
      }
    });

    dialogRef.afterClosed().subscribe(categoryName => {
      if (categoryName) {
        let newCategory = {
          name: categoryName
        };
        this.categoriesService.createCategory(newCategory).subscribe(
          response => {
            // console.warn('createCategory response ->', response);
            this.toastr.info('Category was successfully created!', 'Info');
            this.getCategoriesData();
          },
          error => {
            // console.warn('createCategory err ->', error); // example
            this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
          }
        );
      }
    });
  }

  saveCategory(category: Category) {
    // logger('category', '', '', category);
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Save category',
        message: 'Are you sure you want to save category?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        let newData: any = {
          name: category.name
        };

        newData.properties = category.properties.map(item => {
          let prop = JSON.parse(JSON.stringify(item));
          if (item.isLocal) {
            prop.id = null;
            delete prop.isLocal;
          }
          if (item.values.length === 0) {
            prop.values = [
              {
                id: null,
                value: 'N/A'
              }
            ];
          }
          return prop;
        });

        this.categoriesService.updateCategory(category.id, newData).subscribe(
          response => {
            // console.warn('response', response);
            category.name = response.body.name;
            category.properties = response.body.properties.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
            this.toastr.info('Category was successfully updated!', 'Info');
            // this.getCategoriesData();
            category.isEdited = false;
          },
          error => {
            // console.warn('updateCategory err ->', error); // example
            this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
          }
        );
      }
    });
  }

  removeCategory(category: Category) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Remove category',
        message: 'Are you sure you want to remove category?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.categoriesService.deleteCategory(category.id).subscribe(
          response => {
            this.toastr.info('Category was successfully removed!', 'Info');
            this.getCategoriesData();
          },
          error => {
            // console.warn('deleteCategory err ->', error); // example
            this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
          }
        );
      }
    });
  }

  addNewProperty(category: Category) {
    if (category.newPropertyName) {
      if (isItemNotInArray(category.properties, category.newPropertyName.toLowerCase(), 'name', false, true)) {
        category.properties.push({ id: uuid.v4(), name: category.newPropertyName, values: [], isLocal: true, order: category.properties.length });
        category.newPropertyName = '';
        category.isEdited = true;
      } else {
        this.toastr.warning('Property already is in the list.', 'Attention!');
      }
    } else {
      this.toastr.warning('Please enter new property name.', 'Attention!');
    }
  }

  setCurrentProperty(category: Category, property) {
    category.currentProperty = property;
    category.currentProperty.values.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
  }

  renameProperty(category: Category, property: CategoryProperty) {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      panelClass: 'panel-prompt',
      data: {
        title: 'Rename property',
        message: 'Please input new property name',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Save',
        inputData: property.name
      }
    });

    dialogRef.afterClosed().subscribe(newPropertyName => {
      if (newPropertyName && newPropertyName !== property.name) {
        if (isItemNotInArray(category.properties, newPropertyName.toLowerCase(), 'name', false, true)) {
          property.name = newPropertyName;
          category.isEdited = true;
        } else {
          this.toastr.warning('Property already is in the list.', 'Attention!');
        }
      }
    });
  }

  removeProperty(category: Category, property: CategoryProperty) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Remove property',
        message: 'Are you sure you want to remove "' + property.name + '" property?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        if (category.currentProperty && category.currentProperty.id === property.id) {
          category.currentProperty = null;
        }
        category.properties = category.properties.filter(item => item !== property);
        category.isEdited = true;
      }
    });
  }

  addNewValue(category: Category) {
    if (category.currentProperty) {
      if (category.newValueName) {
        if (isItemNotInArray(category.currentProperty.values, category.newValueName.toLowerCase(), 'value', false, true)) {
          let index = category.properties.findIndex(el => el.id === category.currentProperty.id);
          if (index >= 0) {
            category.properties[index].values.push({ id: null, value: category.newValueName, order: category.properties[index].values.length });
            category.newValueName = '';
            category.isEdited = true;
          }
        } else {
          this.toastr.warning('Value already is in the list.', 'Attention!');
        }
      } else {
        this.toastr.warning('Please enter new value name.', 'Attention!');
      }
    } else {
      this.toastr.warning('Please select property.', 'Attention!');
    }
  }

  renameValue(category: Category, value: ValueRecord) {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      panelClass: 'panel-prompt',
      data: {
        title: 'Rename value',
        message: 'Please input new value',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Save',
        inputData: value.value
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue && newValue !== value.value) {
        let index = category.properties.findIndex(el => el.id === category.currentProperty.id);
        if (index >= 0) {
          if (isItemNotInArray(category.properties[index].values, newValue.toLowerCase(), 'value', false, true)) {
            value.value = newValue;
            category.isEdited = true;
          } else {
            this.toastr.warning('Value already is in the list.', 'Attention!');
          }
        }
      }
    });
  }

  removeValue(category: Category, value: ValueRecord) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Remove value',
        message: 'Are you sure you want to remove "' + value.value + '"  value?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        let index = category.properties.findIndex(el => el.id === category.currentProperty.id);
        if (index >= 0) {
          category.properties[index].values = category.properties[index].values.filter(item => item !== value);
          category.isEdited = true;
        }
      }
    });
  }

  dropProperty(event: CdkDragDrop<string[]>, category) {
    // console.warn('event > ', event);
    // console.warn('category > ', category);

    moveItemInArray(category.properties, event.previousIndex, event.currentIndex);
    category.properties.forEach((prop,idx) => {
      prop.order = idx;
    });
    category.isEdited = true;
  }

  dropValue(event: CdkDragDrop<string[]>, property, category) {
    // console.warn('event > ', event);
    // console.warn('property > ', property);

    moveItemInArray(property.values, event.previousIndex, event.currentIndex);
    property.values.forEach((val,idx) => {
      val.order = idx;
    });
    category.isEdited = true;
  }
}
