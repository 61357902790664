import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'asc';

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  getAgencies(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/agencies?page=${this.page}&size=${this.size}&orderBy=${this.orderBy}&ordering=${this.ordering}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  getAgency(agencyId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/agencies/${agencyId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  createAgency(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/agencies/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  updateAgency(agencyId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/agencies/${agencyId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteAgency(agencyId): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/agencies/${agencyId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  getAgenciesNames(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/agencies/names', { observe: 'response' });
  }
}
