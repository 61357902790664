import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SidebarDataService } from 'src/app/services/sidebar-data.service';
import { ProductsService } from 'src/app/services/products.service';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Category, Manufacture, ProductRecord } from 'src/app/interfaces';
import { ToastrService } from 'ngx-toastr';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ProductModalComponent } from 'src/app/modals/product-modal/product-modal.component';
import { isItemNotInArray, ceateDataFromBlob } from 'src/app/global-functions';
import { MediaService } from 'src/app/services/media.service';
import { InfoPopupComponent } from 'src/app/dialogs/info-popup/info-popup.component';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {
  headerTitle = 'Product Management';
  activeSidebarSection = 'Products';
  productSearchInput = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoadingResults = false;
  productsData = [];
  manufactures: Manufacture[] = [{ id: -1, name: 'All Manufactures' }];
  selectedManufacture: Manufacture = { id: -1, name: 'All Manufactures' };
  categories: Category[] = [{ id: -1, name: 'All Categories' }];
  selectedCategory: Category = { id: -1, name: 'All Categories' };
  dataSource: any;
  displayedColumns = [
    'name',
    'image',
    'partNumber',
    // 'manufacturer',
    'category',
    'codeReference',
    'preferences',
    'distributors',
    'msrp',
    'actions'
  ];

  constructor(
    private sidebarData: SidebarDataService,
    public productsService: ProductsService,
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public mediaService: MediaService
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
    this.productsService.setName('');
    this.getProductsData();
  }

  getProductsData() {
    this.isLoadingResults = true;
    this.productsService.setPage(this.paginator.pageIndex);
    this.productsService.setSize(this.paginator.pageSize || this.productsService.getSize());
    this.productsService.setName(this.productSearchInput);
    this.productsService.getProducts().subscribe(
      response => {
        // console.warn('response', response);
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      error => {
        // console.warn('getProductsData err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  prepareDataSourse(data: ProductRecord[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.productsData = JSON.parse(JSON.stringify(data)).map((item: ProductRecord) => {
      item.codeReferenceList = '';
      let crLisst = [];
      item.codeReferenceGroups.forEach(i => {
        if (isItemNotInArray(crLisst, i)) {
          crLisst.push(i);
        }
      });
      item.codeReferenceList = crLisst.join(', ');

      if (item.photo) {
        this.mediaService.getMedia(item.photo, 'product').subscribe(
          result => {
            if (result.status === 200) {
              ceateDataFromBlob(result.body).then(
                data => {
                  item.photoData = data;
                },
                err => {
                  // console.warn('ceateDataFromBlob err ', err);
                }
              );
            }
          },
          err => {
            // console.warn('mediaService.getMedia err ', err);
          }
        );
      }

      return item;
    });

    const loadImages = (arr: any[]) => {
      this.isLoadingResults = true;
      let products = JSON.parse(JSON.stringify(arr));
      if (arr.length > 0) {
        let product = products.pop();
        this.mediaService.getMedia(product.photo, 'product').subscribe(
          result => {
            if (result.status === 200) {
              ceateDataFromBlob(result.body).then(
                data => {
                  this.productsData.find(item => item.id === product.id).photoData = data;
                  loadImages(products);
                },
                err => {
                  // console.warn('ceateDataFromBlob err ', err);
                  loadImages(products);
                }
              );
            } else {
              loadImages(products);
            }
          },
          err => {
            // console.warn('mediaService.getMedia err ', err);
            loadImages(products);
          }
        );
      } else {
        this.isLoadingResults = false;
      }
    };

    // loadImages(this.productsData.filter(pr => pr.photo)); // loading images one by one

    this.dataSource = new MatTableDataSource(this.productsData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        // case 'date':
        //   return new Date(item.updated);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.productsService.setOrderBy(event.active);
    this.productsService.setOrdering(event.direction);
    this.getProductsData();
  }

  changeManufacture(event) {
    this.productsService.setManufacture(event ? event.id : event);
    this.getProductsData();
  }

  changeCategory(event) {
    this.productsService.setCategory(event ? event.id : event);
    this.getProductsData();
  }

  viewPreferences(product: ProductRecord) {
    // console.warn('viewPreferences -> ', product);
    if (product.propertyValues.length > 0) {
      const objToStr = obj => {
        var result = [];
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            result.push(key, obj[key]);
          }
        }
        return result.join(' - ');
      };

      const dialogRef = this.dialog.open(InfoPopupComponent, {
        maxWidth: '50vw',
        data: {
          title: 'View Property values of ' + product.name,
          content: `<ul>${product.propertyValues
            .map(i => '<li>' + objToStr(i) + '</li>')
            .join('_*_*_')
            .replace(/\_\*\_\*\_/g, '')}</ul>`
        }
      });
    } else {
      this.toastr.warning('There are no Property values in this product.', 'Attention!');
    }
  }

  viewDistributors(product: ProductRecord) {
    // console.warn('viewDistributors -> ', product);

    if (product.distributors.length > 0) {
      const dialogRef = this.dialog.open(InfoPopupComponent, {
        maxWidth: '50vw',
        data: {
          title: 'View Distributos of ' + product.name,
          content: `<ul>${product.distributors
            .map(i => '<li>' + i + '</li>')
            .join('_*_*_')
            .replace(/\_\*\_\*\_/g, '')}</ul>`
        }
      });
    } else {
      this.toastr.warning('There are no Distributors in this product.', 'Attention!');
    }
  }

  editProduct(product: ProductRecord) {
    this.isLoadingResults = true;

    this.productsService.getProduct(product.id).subscribe(
      resp => {
        let productWithPhoto = JSON.parse(JSON.stringify(resp.body));
        productWithPhoto.photoData = product.photoData;

        const dialogRef = this.dialog.open(ProductModalComponent, {
          width: '93vw',
          height: '95vh',
          maxWidth: 'none',
          panelClass: 'product-modal-panel',
          data: {
            title: 'Edit Product ' + product.name,
            product: productWithPhoto
          }
        });

        dialogRef.afterClosed().subscribe(response => {
          if (response === 'success') {
            this.getProductsData();
          }
        });

        this.isLoadingResults = false;
      },
      error => {
        this.isLoadingResults = false;
      }
    );
  }

  deleteProduct(product: ProductRecord) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete product?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.productsService.deleteProduct(product.id).subscribe(
          result => {
            // console.warn('result DEL product', result);
            this.getProductsData();
          },
          error => {
            // console.warn('error DEL product', error);
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  addNewProduct() {
    const dialogRef = this.dialog.open(ProductModalComponent, {
      width: '93vw',
      height: '95vh',
      maxWidth: 'none',
      panelClass: 'product-modal-panel',
      data: {
        title: 'New Product'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response === 'success') {
        this.getProductsData();
      }
    });
  }
}
