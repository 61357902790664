import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { getTimeZoneOffset } from '../global-functions';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  page = 0;
  size = 10;
  orderBy = 'creationTime';
  ordering = 'desc';
  agency = '';
  user = '';
  dateFrom = '';
  dateTo = '';
  productCategory = '';
  brand = '';
  distributor = '';
  msrpFrom = '';
  msrpTo = '';

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setAgency(agency: string) {
    this.agency = agency;
  }

  public getAgency() {
    return this.agency;
  }

  public setUser(user: string) {
    this.user = user;
  }

  public getUser() {
    return this.user;
  }

  public setProductCategory(productCategory: string) {
    this.productCategory = productCategory;
  }

  public getProductCategory() {
    return this.productCategory;
  }

  public setBrand(brand: string) {
    this.brand = brand;
  }

  public getBrand() {
    return this.brand;
  }

  public setDistributor(distributor: string) {
    this.distributor = distributor;
  }

  public getDistributor() {
    return this.distributor;
  }

  public setMsrpFrom(msrpFrom: string) {
    this.msrpFrom = msrpFrom;
  }

  public getMsrpFrom() {
    return this.msrpFrom;
  }

  public setMsrpTo(msrpTo: string) {
    this.msrpTo = msrpTo;
  }

  public getMsrpTo() {
    return this.msrpTo;
  }

  public setDateFrom(dateFrom: string) {
    this.dateFrom = dateFrom
      ? moment(+new Date(dateFrom) - getTimeZoneOffset()) // * +- Server TIMEZONE
          .toDate()
          .toISOString()
      : '';
  }

  public getDateFrom() {
    return this.dateFrom;
  }

  public setDateTo(dateTo: string) {
    this.dateTo = dateTo
      ? moment(+new Date(dateTo) - getTimeZoneOffset()) // * +- Server TIMEZONE
          .toDate()
          .toISOString()
      : '';
  }

  public getDateTo() {
    return this.dateTo;
  }

  getReports(): Observable<any> {
    let url = `web/reports?page=${this.page}&size=${this.size}&orderBy=${
      this.orderBy
    }&ordering=${this.ordering}${
      this.agency && +this.agency >= 0 ? '&agency=' + this.agency : ''
    }${this.user && +this.user >= 0 ? '&user=' + this.user : ''}${
      this.dateFrom ? '&dateFrom=' + this.dateFrom : ''
    }${this.dateTo ? '&dateTo=' + this.dateTo : ''}`;

    if (this.productCategory && +this.productCategory >= 0) {
      url += `&productCategory=${this.productCategory}`;
    }

    if (this.brand && +this.brand >= 0) {
      url += `&brand=${this.brand}`;
    }

    if (this.distributor && +this.distributor >= 0) {
      url += `&distributor=${this.distributor}`;
    }

    if (this.msrpFrom && +this.msrpFrom >= 0) {
      url += `&msrpFrom=${this.msrpFrom}`;
    }

    if (this.msrpTo && +this.msrpTo >= 0) {
      url += `&msrpTo=${this.msrpTo}`;
    }

    return this.http.get(this.build.getApiLink() + url, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }

  getReportsTotalPrice(agencyId): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/reports/total?agency=${agencyId}${
          this.user ? '&user=' + this.user : ''
        }${this.dateFrom ? '&dateFrom=' + this.dateFrom : ''}${
          this.dateTo ? '&dateTo=' + this.dateTo : ''
        }`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  getReport(reportId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/reports/${reportId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }

  getReportCSV(reportId): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/reports/${reportId}/csv`,
      {
        observe: 'response',
      }
    );
  }

  getReportPDF(reportId): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/reports/${reportId}/pdf`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  deleteReport(reportId: string): Observable<any> {
    return this.http.delete(
      this.build.getApiLink() + `web/reports/${reportId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }


  getBrandsNames(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/brands/names', { observe: 'response' });
  }
}
