import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BuildService } from "./build.service";

@Injectable({
  providedIn: "root",
})
export class ReportExecutiveSummaryService {
  constructor(private http: HttpClient, private build: BuildService) {}

  getExecutiveSummary(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/presets/executiveSummary`,
      {
        responseType: "text",
      }
    );
  }

  setExecutiveSummary(data): Observable<any> {
    return this.http.put(
      this.build.getApiLink() + `web/presets/executiveSummary`,
      data,
      {
        observe: "response",
      }
    );
  }
}
