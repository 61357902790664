import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { DistributorsService } from 'src/app/services/distributors.service';
import { PromptPopupComponent } from 'src/app/dialogs/prompt-popup/prompt-popup.component';
import { ImportCsvComponent } from 'src/app/modals/import-csv/import-csv.component';

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DistributorsComponent implements OnInit {
  headerTitle = 'Distributor Management';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoadingResults = false;
  distributorsData = [];
  dataSource: any;
  displayedColumns: string[] = ['distributorName', 'unique', 'actions'];
  distributorSearchInput = '';

  constructor(
    public distributorsService: DistributorsService,
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.getDistributorsData();
  }

  getDistributorsData() {
    this.isLoadingResults = true;
    this.distributorsService.setPage(this.paginator.pageIndex);
    this.distributorsService.setSize(this.paginator.pageSize || this.distributorsService.getSize());
    this.distributorsService.setName(this.distributorSearchInput);
    this.distributorsService.getDistributors().subscribe(
      response => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      error => {
        // console.warn('getdistributorsData err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  prepareDataSourse(data: any[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.distributorsData = JSON.parse(JSON.stringify(data)).map(item => {
      item.unique = item.uniqueProduct ? 'Yes' : 'No';

      return item;
    });

    this.dataSource = new MatTableDataSource(this.distributorsData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.distributorsService.setOrderBy(event.active);
    this.distributorsService.setOrdering(event.direction);
    this.getDistributorsData();
  }

  createDistributor() {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      width: 'auto',
      data: {
        title: `Create distributor`,
        message: 'Please input new distributor name',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Create',
        maxLength: 250
      }
    });

    dialogRef.afterClosed().subscribe(distributorName => {
      if (distributorName) {
        this.distributorsService.createDistributor({ name: distributorName }).subscribe(
          res => {
            // console.warn('createDistributor res ->', res); // example
            this.getDistributorsData();
            this.toastr.info('Distributor was successfully created!', 'Info');
          },
          error => {
            // console.warn('createDistributor err ->', err); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  renameDistributor(distributor) {
    const dialogRef = this.dialog.open(PromptPopupComponent, {
      width: 'auto',
      data: {
        title: `Rename ${distributor.name} distributor`,
        message: 'Please input new distributor name',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Rename',
        maxLength: 250,
        inputData: distributor.name
      }
    });

    dialogRef.afterClosed().subscribe(distributorName => {
      if (distributorName) {
        this.distributorsService.updateDistributor(distributor.id, { name: distributorName }).subscribe(
          res => {
            // console.warn('updateDistributor res ->', res); // example
            this.getDistributorsData();
            this.toastr.info('Distributor was successfully renamed!', 'Info');
          },
          error => {
            // console.warn('updateDistributor err ->', err); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  importCSV(distributor) {
    const dialogRef = this.dialog.open(ImportCsvComponent, {
      width: 'auto',
      data: {
        distributor
      }
    });
  }

  deleteDistributor(distributor) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete distributor',
        message: 'Are you sure you want to delete distributor?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.distributorsService.deleteDistributor(distributor.id).subscribe(
          result => {
            // console.warn('result DEL distributor', result);
            this.getDistributorsData();
          },
          error => {
            // console.warn('error DEL distributor', error);
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }
}
