import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarDataService {
  openedSection = '';
  openedSectionUpdated: Subject<any> = new Subject<any>();

  constructor() {}

  setOpenedSection(title: string): void {
    this.openedSection = title;
    this.openedSectionUpdated.next(title);
  }

  getOpenedSection(): string {
    return this.openedSection;
  }
}
