import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/services/reports.service';
import { isItemNotInArray, ceateDataFromBlob } from 'src/app/global-functions';
import * as uuid from 'uuid';
import { MediaService } from 'src/app/services/media.service';
import { ProductsService } from 'src/app/services/products.service';
import { DistributorsService } from 'src/app/services/distributors.service';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewReportComponent implements OnInit {
  isLoading = false;
  isLoadingProducts = false;
  report;
  allCodeReferences = [];
  allSelectedCodeReferences = [];
  allObservationsMedia = [];

  constructor(
    public dialogRef: MatDialogRef<ViewReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    private reportsService: ReportsService,
    private mediaService: MediaService,
    private productsService: ProductsService,
    private distributorsService: DistributorsService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    if (this.data.report) {
      this.reportsService.getReport(this.data.report.id).subscribe(data => {
        this.report = data.body;
        // console.warn('report > ', this.report);
        this.prepareData();
      });
    }
  }

  getMedia(arr: any[], type, element) {
    let array = JSON.parse(JSON.stringify(arr));
    let promise = new Promise((resolve, reject) => {
      if (array.length > 0) {
        const createMedia = (array: [], type, element) => {
          let newMedia = array.pop();
          this.mediaService.getMedia(newMedia, type).subscribe(
            result => {
              if (result.status === 200) {
                ceateDataFromBlob(result.body).then(
                  data => {
                    // console.warn('data result', data);
                    element.images.push({
                      id: uuid.v4(),
                      name: newMedia,
                      media: data,
                      main: element.areaPhotos[0] === newMedia
                    });
                    if (array.length > 0) {
                      createMedia(array, type, element);
                    } else {
                      resolve();
                    }
                  },
                  err => {
                    // console.warn('createMedia err ', err);
                    reject();
                  }
                );
              }
            },
            err => {
              // console.warn('createMedia err ', err);
              reject();
            }
          );
        };
        createMedia(array, type, element);
      } else {
        resolve();
      }
    });
    return promise;
  }

  prepareData() {
    this.isLoading = true;
    let index = 0;
    this.report.observations.forEach((observation: any) => {
      observation.images = [];
      observation.sortOrder =
        observation.priority === 'CRITICAL' ? 1 : observation.priority === 'IMPORTANT' ? 2 : observation.priority === 'BEST' ? 3 : 4;

      this.getMedia(observation.areaPhotos, 'area', observation).then(() => {
        observation.images.sort((a, b) => (a.main === b.main ? 0 : a.main ? -1 : 1));
        this.isLoading = !(index + 1 === this.report.observations.length);
        index++;
      });

      observation.products.forEach(product => {
        this.mediaService.getMedia(product.photo, 'product').subscribe(
          result => {
            if (result.status === 200) {
              ceateDataFromBlob(result.body).then(
                data => {
                  product.photoData = data;
                },
                err => {
                  // console.warn('ceateDataFromBlob err ', err);
                }
              );
            }
          },
          err => {
            // console.warn('mediaService.getMedia err ', err);
          }
        );

        product.references.forEach((codeReference: any) => {
          if (isItemNotInArray(this.allSelectedCodeReferences, codeReference.description)) {
            this.allSelectedCodeReferences.push(codeReference.description);
          }
        });
      });
    });

    this.report.observations.sort((a, b) => (a.sortOrder !== b.sortOrder ? (a.sortOrder < b.sortOrder ? -1 : 1) : 0));
    if (this.report.observations.length < 1) {
      this.isLoading = false;
    }
  }

  // filterActiveItems(arr: any[], prop?) {
  //   if (prop) {
  //     return arr.filter(i => i[prop]);
  //   } else {
  //     return arr.filter(i => i.active);
  //   }
  // }
}
