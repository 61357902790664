import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;
  redirectUrl = '/';

  constructor(private http: HttpClient, private router: Router, private build: BuildService) {}

  authenticate(login: string, password: string, callbackSuccess?: () => any, callbackError?: () => any) {
    const body = new HttpParams().set('email', login).set('password', password);

    this.http
      .post(this.build.getApiLink() + 'web/login', body.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        observe: 'response'
      })
      .subscribe(
        result => {
          // console.warn('Login Success -> ', result);
          if (result.status === 200) {
            this.authenticated = true;
            this.router.navigate([this.redirectUrl]);
            window.localStorage.setItem('SL-UD', ' ');
          } else {
            this.authenticated = false;
            window.localStorage.removeItem('SL-UD');
          }
          return callbackSuccess && callbackSuccess();
        },
        error => {
          // console.warn('Login Error ->', error);
          window.localStorage.removeItem('SL-UD');
          return callbackError && callbackError();
        }
      );
  }

  isAuthenticated() {
    return this.authenticated;
  }

  setAuthenticated(value: boolean) {
    this.authenticated = value;
  }

  logout(): void {
    this.http
      .post(this.build.getApiLink() + 'web/logout', {})
      .toPromise()
      .finally(() => {
        this.authenticated = false;
        window.localStorage.removeItem('SL-UD');
        window.location.reload();
      });
  }
}
