import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';

import { MainContentRoutingModule } from './main-content-routing.module';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MainContentComponent } from './main-content.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SafeHtml } from '../pipes/safe-html.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { HeaderDataService } from '../services/header-data.service';
import { SidebarDataService } from '../services/sidebar-data.service';
import { FileFilterByNamePipe } from '../pipes/file-filter-by-name.pipe';

import { ConfirmPopupComponent } from '../dialogs/confirm-popup/confirm-popup.component';
import { PromptPopupComponent } from '../dialogs/prompt-popup/prompt-popup.component';
import { InfoPopupComponent } from '../dialogs/info-popup/info-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportsComponent } from './reports/reports.component';
import { ProductsComponent } from './products/products.component';
import { CategoriesComponent } from './categories/categories.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { ImportDataComponent } from './import-data/import-data.component';
import { ProductModalComponent } from '../modals/product-modal/product-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropComponent } from '../modals/image-crop/image-crop.component';
import { ViewReportComponent } from '../modals/view-report/view-report.component';
import { UsersComponent } from './users/users.component';
import { UserInfoModalComponent } from '../modals/user-info-modal/user-info-modal.component';
import { DistributorsComponent } from './distributors/distributors.component';
import { ImportCsvComponent } from '../modals/import-csv/import-csv.component';
import { CodeReferencesComponent } from './code-references/code-references.component';
import { CodeReferenceModalComponent } from '../modals/code-reference-modal/code-reference-modal.component';
import { CodeReferenceGroupsModalComponent } from '../modals/code-reference-groups-modal/code-reference-groups-modal.component';
import { ApplicationUpdatesComponent } from './application-updates/application-updates.component';
import { AgencyManagementComponent } from './agency-management/agency-management.component';
import { ReportExecutiveSummaryComponent } from './report-executive-summary/report-executive-summary.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

library.add(fas, far, fab);

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    MainContentComponent,
    PageNotFoundComponent,
    SafeHtml,
    FileFilterByNamePipe,
    ConfirmPopupComponent,
    PromptPopupComponent,
    InfoPopupComponent,
    ReportsComponent,
    ProductsComponent,
    CategoriesComponent,
    ManufacturersComponent,
    ImportDataComponent,
    ProductModalComponent,
    ImageCropComponent,
    ViewReportComponent,
    UsersComponent,
    UserInfoModalComponent,
    DistributorsComponent,
    ImportCsvComponent,
    CodeReferencesComponent,
    CodeReferenceModalComponent,
    CodeReferenceGroupsModalComponent,
    ApplicationUpdatesComponent,
    AgencyManagementComponent,
    ReportExecutiveSummaryComponent
  ],
  imports: [
    CommonModule,
    MainContentRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    NgSelectModule,
    ImageCropperModule
  ],
  entryComponents: [
    ConfirmPopupComponent,
    PromptPopupComponent,
    InfoPopupComponent,
    ProductModalComponent,
    ImageCropComponent,
    ViewReportComponent,
    UserInfoModalComponent,
    ImportCsvComponent,
    CodeReferenceModalComponent,
    CodeReferenceGroupsModalComponent
  ],
  providers: [
    HeaderDataService,
    SidebarDataService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class MainContentModule {}
