import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { ApplicationService } from 'src/app/services/application.service';
import { InfoPopupComponent } from 'src/app/dialogs/info-popup/info-popup.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-application-updates',
  templateUrl: './application-updates.component.html',
  styleUrls: ['./application-updates.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplicationUpdatesComponent implements OnInit {
  headerTitle = 'Application Updater';
  isLoadingResults = false;
  isLoading = [];
  newVersion = [];
  newChangeLog = [];
  newCompatibleVersion = [];
  uploadedApplications = [];
  applications = [];
  iosData;
  androidData;
  desktopData;

  constructor(
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public applicationService: ApplicationService
  ) {}

  ngOnInit() {
    this.headerDataService.setTitle(this.headerTitle);
    this.isLoadingResults = true;
    this.applicationService
      .getIos()
      .toPromise()
      .then(data => {
        this.iosData = JSON.parse(JSON.stringify(data.body));
      })
      .finally(() => {
        this.applicationService
          .getAndroid()
          .toPromise()
          .then(data => {
            this.androidData = JSON.parse(JSON.stringify(data.body));
          })
          .finally(() => {
            this.applicationService
              .getDesktop()
              .toPromise()
              .then(data => {
                this.desktopData = JSON.parse(JSON.stringify(data.body));
              })
              .finally(() => {
                this.prepareData();
                this.isLoadingResults = false;
              });
          });
      });
  }

  prepareData() {
    // console.warn('iosData >> ', this.iosData);
    // console.warn('androidData >> ', this.androidData);
    // console.warn('desktopData >> ', this.desktopData);

    if (this.iosData) {
      let result = this.iosData.data.reduce((prev, curr) => {
        if (prev) {
          if (+new Date(prev.created) > +new Date(curr.created)) {
            return prev;
          } else {
            return curr;
          }
        } else {
          return curr;
        }
      }, {});
      result.type = 'ios';
      result.title = 'iOS Application';
      result.icon = 'apple';
      this.applications.push(result);
    }

    if (this.androidData) {
      let result = this.androidData.data.reduce((prev, curr) => {
        if (prev) {
          if (+new Date(prev.created) > +new Date(curr.created)) {
            return prev;
          } else {
            return curr;
          }
        } else {
          return curr;
        }
      }, {});
      result.type = 'android';
      result.title = 'Android Application';
      result.icon = 'android';
      this.applications.push(result);
    }

    if (this.desktopData) {
      let result = this.desktopData.data.reduce((prev, curr) => {
        if (prev) {
          if (+new Date(prev.created) > +new Date(curr.created)) {
            return prev;
          } else {
            return curr;
          }
        } else {
          return curr;
        }
      }, {});
      result.type = 'desktop';
      result.title = 'Desktop Application';
      result.icon = 'bolt';
      this.applications.push(result);
    }
  }

  showLog(app) {
    const dialogRef = this.dialog.open(InfoPopupComponent, {
      maxWidth: '50vw',
      data: {
        title: 'Change log for ' + app.type.toUpperCase(),
        content: `<ul>${app.changeLog
          .split(';')
          .map(i => '<li>' + i + '</li>')
          .join('_*_*_')
          .replace(/\_\*\_\*\_/g, '')}</ul>`
      }
    });
  }

  dropped(files: NgxFileDropEntry[], platform, type) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // console.log(droppedFile.relativePath, fileEntry);
          // console.log(droppedFile.relativePath, file);
          if (/./g.test(file.name)) {
            // TODO: change logic with server upload
            // TODO: Upload to server

            
            if (!this.uploadedApplications[platform]) {
              this.uploadedApplications[platform] = [];
            }
            this.uploadedApplications[platform][type] = file.name;
          } else {
            this.toastr.warning(`Wrong file type "${droppedFile.relativePath}"`, 'Attention!');
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  submitUpdate(i?) {}
}
