import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Agency, User } from 'src/app/interfaces';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { AgencyService } from 'src/app/services/agency.service';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { UserInfoModalComponent } from 'src/app/modals/user-info-modal/user-info-modal.component';
import roles from 'src/app/config-data/roles';
import { SidebarDataService } from 'src/app/services/sidebar-data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {
  headerTitle = 'User Management';
  activeSidebarSection = 'Users';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoadingResults = false;
  usersData = [];
  agencies: Agency[] = [{ id: -1, name: 'All Agencies' }];
  selectedAgency: Agency = { id: -1, name: 'All Agencies' };
  roles: any[];
  selectedRole: any = { id: -1, name: 'All Roles' };
  dataSource: any;
  displayedColumns: string[] = ['name', 'email', 'phone', 'type', 'manager', 'agency', 'actions'];

  constructor(
    public headerDataService: HeaderDataService,
    private toastr: ToastrService,
    public userService: UserService,
    private agencyService: AgencyService,
    public dialog: MatDialog,
    private sidebarData: SidebarDataService
  ) {}

  ngOnInit() {
    this.roles = roles;
    this.headerDataService.setTitle(this.headerTitle);
    this.sidebarData.setOpenedSection(this.activeSidebarSection);
    this.getUsersData();
    this.getAgenciesData();
  }

  ngOnDestroy() {
    this.userService.setAgency('');
    this.userService.setRole('');
  }

  getUsersData() {
    this.isLoadingResults = true;
    this.userService.setPage(this.paginator.pageIndex);
    this.userService.setSize(this.paginator.pageSize || this.userService.getSize());
    this.userService.getUsers().subscribe(
      response => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
      },
      error => {
        // console.warn('getUsersData err ->', error); // example
        this.toastr.error('Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''), 'Oops!');
      }
    );
  }

  getAgenciesData() {
    this.agencyService.getAgenciesNames().subscribe(agencies => {
      this.agencies = this.agencies.concat(agencies.body);
    });
  }

  prepareDataSourse(data: any[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    this.usersData = JSON.parse(JSON.stringify(data)).map(item => {
      if (item.role === 'ADMIN') item.role = 'Administrator';
      if (item.role === 'J_MANAGER') item.role = 'Justrite Manager';
      if (item.role === 'A_MANAGER') item.role = 'Agency Manager';
      if (item.role === 'REPRESENTATIVE') item.role = 'Representative';

      return item;
    });

    this.dataSource = new MatTableDataSource(this.usersData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        // case 'created':
        // return new Date(item.created);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.userService.setOrderBy(event.active);
    this.userService.setOrdering(event.direction);
    this.getUsersData();
  }

  changeAgency(event) {
    this.userService.setAgency(event ? event.id : event);
    this.getUsersData();
  }

  changeRole(event) {
    this.userService.setRole(event);
    this.getUsersData();
  }

  addUser() {
    const dialogRef = this.dialog.open(UserInfoModalComponent, {
      width: 'auto',
      panelClass: 'user-info-modal-panel',
      data: {
        title: 'Add New User'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.getUsersData();
      }
    });
  }

  editUser(user: User) {
    // console.warn('editUser > ', user);
    const dialogRef = this.dialog.open(UserInfoModalComponent, {
      width: 'auto',
      panelClass: 'user-info-modal-panel',
      data: {
        title: 'Edit User Settings',
        user
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.getUsersData();
      }
    });
  }

  deleteUser(user: User) {
    // console.warn('deleteUser > ', user);
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete User',
        message: 'Are you sure you want to delete user?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes'
      }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.userService.deleteUser(user.id).subscribe(
          result => {
            // console.warn('result DEL user', result);
            this.getUsersData();
          },
          error => {
            // console.warn('error DEL user', error);
            this.toastr.error(
              (error.error && error.error.message ? error.error.message : ''),
              '',
              { enableHtml: true }
            );
          }
        );
      }
    });
  }
}
