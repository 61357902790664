import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'desc';
  name = '';
  category = '';
  manufacture = '';

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setName(name: string) {
    this.name = name;
  }

  public getName() {
    return this.name;
  }

  public setCategory(category: string) {
    this.category = category;
  }

  public getCategory() {
    return this.category;
  }

  public setManufacture(manufacture: string) {
    this.manufacture = manufacture;
  }

  public getManufacture() {
    return this.manufacture;
  }

  getProducts(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/products?page=${this.page}&size=${this.size}&orderBy=${this.orderBy}&ordering=${this.ordering}${
          this.name ? '&name=' + this.name : ''
        }${this.category && +this.category !== -1 ? '&category=' + this.category : ''}${
          this.manufacture && +this.manufacture !== -1 ? '&manufacture=' + this.manufacture : ''
        }`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  getProduct(productId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/products/${productId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  createProduct(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/products/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  updateProduct(productId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/products/${productId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteProduct(productId: string | number): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/products/${productId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  searchProduct(string): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/products/search?text=${string}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }
}
