import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  MatSort,
  MatPaginator,
  MatTableDataSource,
  MatDialog,
} from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { ReportRecord, Agency, User } from 'src/app/interfaces';
import { FormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AgencyService } from 'src/app/services/agency.service';
import { HeaderDataService } from 'src/app/services/header-data.service';
import { ConfirmPopupComponent } from 'src/app/dialogs/confirm-popup/confirm-popup.component';
import { ViewReportComponent } from 'src/app/modals/view-report/view-report.component';
import { Subscription } from 'rxjs';
import { DistributorsService } from 'src/app/services/distributors.service';
import { CategoriesService } from 'src/app/services/categories.service';

export function parseDate(str: string) {
  const dateA: any = str && str.split(/[^0-9]/);
  const EAST = 4;

  return str
    ? new Date(
        dateA[0],
        dateA[1] - 1,
        dateA[2],
        dateA[3] - EAST,
        dateA[4],
        dateA[5]
      )
    : '---';
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ReportsComponent implements OnInit, OnDestroy {
  headerTitle = 'STUD-E Reports';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  nowDate = new Date();
  dateFromControl = new FormControl();
  dateToControl = new FormControl();
  @ViewChild('dateFrom', { static: true }) dateFrom: ElementRef;
  @ViewChild('dateTo', { static: true }) dateTo: ElementRef;
  isLoadingResults = false;
  reportsData = [];
  agencies: Agency[] = [{ id: -1, name: 'All Agencies' }];
  selectedAgency: Agency = { id: -1, name: 'All Agencies' };
  users: User[] = [{ id: -1, name: 'All Users' }];
  allUsers = [];
  selectedUser: User = { id: -1, name: 'All Users' };
  dataSource: any;
  displayedColumns: string[] = [
    'creationTime',
    'reportName',
    'submittedBy',
    'agency',
    'customer',
    'distributor',
    'estimatedPrice',
    'actions',
  ];
  user = null;
  aSub: Subscription;
  serviceUser;
  isAgencyManager = false;

  productCategories = [{ id: -1, name: 'All Product Categories' }];
  selectedProductCategory = { id: -1, name: 'All Product Categories' };
  brands = [{ id: -1, name: 'All Brands' }];
  selectedBrand = { id: -1, name: 'All Brands' };
  distributors = [{ id: -1, name: 'All Distributors' }];
  selectedDistributor = { id: -1, name: 'All Distributors' };
  msrpFromControl = new FormControl();
  msrpToControl = new FormControl();
  typingTimer;
  doneTypingInterval = 777;
  totalPrice = 0;
  esPriceTitle = 'Estimated Price';

  constructor(
    public reportsService: ReportsService,
    public headerDataService: HeaderDataService,
    public distributorService: DistributorsService,
    public categoriesService: CategoriesService,
    private toastr: ToastrService,
    private userService: UserService,
    private agencyService: AgencyService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.user = user;
    });
    this.serviceUser = await this.userService.getCUser().toPromise();
    if (
      this.serviceUser &&
      this.serviceUser.role == 'A_MANAGER' &&
      this.serviceUser.agency
    ) {
      this.isAgencyManager = true;
      this.reportsService.setAgency(this.serviceUser.agency.id);
    }
    this.headerDataService.setTitle(this.headerTitle);
    this.getUsersData();
    this.getAgenciesData();
    await this.getBrandsData();
    await this.getDistributorsData();
    await this.getProductCategoriesData();
    this.getReportsData();
  }

  ngOnDestroy() {
    this.reportsService.setAgency('');
    this.reportsService.setUser('');
    this.reportsService.setDateFrom('');
    this.reportsService.setDateTo('');
    if (this.aSub) {
      this.aSub.unsubscribe();
    }
  }

  getReportsData() {
    this.isLoadingResults = true;
    this.reportsService.setPage(this.paginator.pageIndex);
    this.reportsService.setSize(
      this.paginator.pageSize || this.reportsService.getSize()
    );
    this.reportsService.getReports().subscribe(
      (response) => {
        this.paginator.length = response.body.metadata.totalElements;
        this.prepareDataSourse(response.body.data);
        this.isLoadingResults = false;
        this.checkBrands(response);
      },
      (error) => {
        // console.warn('getReportsData err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  checkBrands(response) {
    const brand = this.reportsService.getBrand();
    if (brand && +brand >= 0) {
      this.esPriceTitle = 'Brand Estimated Price';
      this.totalPrice = response.body.manufacturerTotalPrice || 0;
    } else {
      this.esPriceTitle = 'Estimated Price';
      this.totalPrice = response.body.totalPrice || 0;
    }
  }

  getUsersData() {
    this.userService.getUsersNames().subscribe((users) => {
      this.allUsers = this.users.concat(users.body);
      this.users = this.users.concat(users.body);
    });
  }

  getAgenciesData() {
    this.agencyService.getAgenciesNames().subscribe((agencies) => {
      this.agencies = this.agencies.concat(agencies.body);
      if (this.isAgencyManager) {
        this.selectedAgency = this.agencies.find(
          (e) => e.id == this.serviceUser.agency.id
        );
        if (this.selectedAgency) {
          this.users = this.selectedAgency.managers.concat(
            this.selectedAgency.representatives
          );
        }
        this.selectedUser = { id: -1, name: 'All Users' };
        this.reportsService.setUser('');
      }
    });
  }

  async getBrandsData() {
    try {
      const response = await this.reportsService.getBrandsNames().toPromise();

      if (!response.body) {
        return;
      }

      this.brands = this.brands.concat(response.body);

      this.reportsService.setDistributor('');
    } catch (error) {
      console.error(
        'Something went wrong. Get brands error ' +
          (error.error && error.error.message ? error.error.message : '')
      );
    }
  }

  async getDistributorsData() {
    const response = await this.distributorService
      .getDistributorsNames(true)
      .toPromise();

    if (!response.body) {
      return;
    }

    this.distributors = this.distributors.concat(response.body);

    this.reportsService.setDistributor('');
  }

  async getProductCategoriesData() {
    const response = await this.categoriesService
      .getCategoriesNames()
      .toPromise();

    if (!response.body) {
      return;
    }

    this.productCategories = this.productCategories.concat(response.body);

    this.reportsService.setProductCategory('');
  }

  prepareDataSourse(data: ReportRecord[]) {
    // console.warn('prepareDataSourse data -> ', data); // example
    const brand = this.reportsService.getBrand();
    let isBrandSelected = false;
    if (brand && +brand >= 0) {
      isBrandSelected = true;
    }

    this.reportsData = JSON.parse(JSON.stringify(data)).map((item) => {
      item.updated = parseDate(item.updated).toLocaleString();
      item.clientCreated = parseDate(item.clientCreated).toLocaleString();
      item.isBrandSelected = isBrandSelected;

      return item;
    });

    this.dataSource = new MatTableDataSource(this.reportsData);

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'creationTime':
          return new Date(item.clientCreated);
        default:
          return item[property];
      }
    };
  }

  changeSorting(event) {
    this.paginator.pageIndex = 0;
    this.reportsService.setOrderBy(event.active);
    this.reportsService.setOrdering(event.direction);
    this.getReportsData();
  }

  changeAgency(event) {
    this.reportsService.setAgency(event ? event.id : event);
    if (event && event.managers && event.representatives) {
      this.users = event.managers.concat(event.representatives);
      this.selectedUser = { id: -1, name: 'All Users' };
      this.reportsService.setUser('');
    } else {
      this.users = this.allUsers;
    }
    this.getReportsData();
  }

  changeUser(event) {
    this.reportsService.setUser(event ? event.id : event);
    this.getReportsData();
  }

  changeProductCategory(event) {
    this.reportsService.setProductCategory(event ? event.id : event);
    this.getReportsData();
  }

  changeBrand(event) {
    this.reportsService.setBrand(event ? event.id : event);
    this.getReportsData();
  }

  changeDistributor(event) {
    this.reportsService.setDistributor(event ? event.id : event);
    this.getReportsData();
  }

  onEstimatedPriceChange(type: string) {
    if (type === 'from') {
      this.msrpFromControl.setValue(
        this.msrpFromControl.value.replace(/[^0-9]*/g, '')
      );
    }

    if (type === 'to') {
      this.msrpToControl.setValue(
        this.msrpToControl.value.replace(/[^0-9]*/g, '')
      );
    }

    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      let from = +this.msrpFromControl.value;
      let to = +this.msrpToControl.value;

      if (from == 0) {
        this.msrpFromControl.setValue('');
        this.reportsService.setMsrpFrom('');
      }

      if (to == 0) {
        this.msrpToControl.setValue('');
        this.reportsService.setMsrpTo('');
      }

      if (type === 'from' && from) {
        if (from && from < 0) {
          from = 0;
          this.msrpFromControl.setValue(from);
        }
        if (from && to && from > to) {
          to = from > 0 ? from + 1 : 1;
          this.msrpToControl.setValue(to);
        }
      }

      if (type === 'to' && to) {
        if (to && to <= 0) {
          to = 1;
          this.msrpToControl.setValue(to);
        }
        if ((to || to == 0) && from && to < from) {
          to = from > 0 ? from + 1 : 1;
          this.msrpToControl.setValue(to);
        }
      }

      this.reportsService.setMsrpFrom(from ? from.toString() : '');
      this.reportsService.setMsrpTo(to ? to.toString() : '');
      this.getReportsData();
    }, this.doneTypingInterval);
  }

  changeDate(typeDate: string, event) {
    if (typeDate === 'dateFrom') {
      this.reportsService.setDateFrom(event ? +new Date(event.value) : event);
    }
    if (typeDate === 'dateTo') {
      this.reportsService.setDateTo(
        event ? +new Date(event.value) + 24 * 60 * 60 * 1000 - 1000 : event
      );
    }
    this.getReportsData();
  }

  escapeDatepicker(type) {
    if (type === 'dateFrom') {
      this.dateFromControl.setValue(null);
      this.changeDate('dateFrom', false);
      this.dateFrom.nativeElement.blur();
    }
    if (type === 'dateTo') {
      this.dateToControl.setValue(null);
      this.changeDate('dateTo', false);
      this.dateTo.nativeElement.blur();
    }
  }

  getReportCSV(report: ReportRecord) {
    this.reportsService.getReportCSV(report.id).subscribe(
      (result) => {
        // console.warn('result CSV', result);
      },
      (error) => {
        // console.warn('error CSV', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
      }
    );
  }

  getReportPDF(report: ReportRecord) {
    this.isLoadingResults = true;
    this.reportsService.getReportPDF(report.id).subscribe(
      (result) => {
        // console.warn('result getReportPDF ++');

        let newBlob = new Blob([result.body], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const data = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = data;
        link.download = report.name
          .replace(/[\.\\/:*?\"<>|]/g, '_')
          .replace(/(?:\r\n|\r|\n)/g, ' ');
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        this.isLoadingResults = false;

        setTimeout(function () {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      (error) => {
        // console.warn('error getReportPDF', error);
        this.toastr.error(
          'Something went wrong. ' +
            (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingResults = false;
      }
    );
  }

  deleteReport(report: ReportRecord) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: 'auto',
      data: {
        title: 'Delete report',
        message: 'Are you sure you want to delete report?',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
      },
    });

    dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.reportsService.deleteReport(report.id).subscribe(
          (result) => {
            // console.warn('result DEL report', result);
            this.getReportsData();
          },
          (error) => {
            // console.warn('error DEL report', error);
            this.toastr.error(
              'Something went wrong. ' +
                (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
          }
        );
      }
    });
  }

  preview(report: ReportRecord) {
    const dialogRef = this.dialog.open(ViewReportComponent, {
      width: '94vw',
      maxWidth: '94vw',
      height: '96vh',
      panelClass: 'preview-report-modal',
      data: {
        report,
      },
    });
  }
}
