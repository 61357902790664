import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BuildService } from './build.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(private http: HttpClient, private build: BuildService) {}

  getMedia(fileName, type): Observable<any> {
    let headersData = {
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
    };
    let headers = new HttpHeaders(headersData);

    return this.http.get(
      this.build.getApiLink() + 'web/media/' + fileName + '?type=' + (type === 'area' ? 'AREA_PHOTO' : 'PRODUCT_PHOTO'),
      {
        observe: 'response',
        responseType: 'blob'
      }
    );
  }
}
