import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BuildService } from './build.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'asc';
  agency = '';
  role: any;
  serviceUser = null;
  private userSubject$ = new BehaviorSubject<any>(this.serviceUser);
  userChanged$ = this.userSubject$.asObservable();

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  public setAgency(agency: string) {
    this.agency = agency;
  }

  public getAgency() {
    return this.agency;
  }

  public setRole(role: any) {
    this.role = role;
  }

  public getRole() {
    return this.role;
  }

  public setServiceUser(user: any) {
    this.serviceUser = user;
  }

  public getServiceUser() {
    return this.serviceUser;
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/users/current').pipe(
      tap((user) => {
        this.setServiceUser(user);
        this.userSubject$.next(this.serviceUser);
      })
    );
  }

  getCUser(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/users/current');
  }

  getUsers(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() +
        `web/users?page=${this.page}&size=${this.size}&orderBy=${
          this.orderBy
        }&ordering=${this.ordering}${
          this.agency && +this.agency >= 0 ? '&agency=' + this.agency : ''
        }${this.role && +this.role.id >= 0 ? '&role=' + this.role.type : ''}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  getUsersNames(role?): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/users/names?${role ? 'role=' + role : ''}`,
      {
        observe: 'response',
      }
    );
  }

  getUser(userId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/users/${userId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }

  createUser(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/users/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }

  updateUser(userId, body): Observable<any> {
    return this.http.put(
      this.build.getApiLink() + `web/users/${userId}`,
      body,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response',
      }
    );
  }

  deleteUser(userId): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/users/${userId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response',
    });
  }
}
