import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipeFilterByName'
})
export class FileFilterByNamePipe implements PipeTransform {
  transform(items: any, search): any {
    return search
      ? items.filter(
          item =>
            (item.name && item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            (item.caption && item.caption.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        )
      : items;
  }
}
