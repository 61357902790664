import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ReportExecutiveSummaryService } from "src/app/services/report-executive-summary.service";

@Component({
  selector: "app-report-executive-summary",
  templateUrl: "./report-executive-summary.component.html",
  styleUrls: ["./report-executive-summary.component.scss"],
})
export class ReportExecutiveSummaryComponent implements OnInit {
  isLoading = false;
  inProgress = false;
  executiveSummaryText = "";

  constructor(
    private reportExecutiveSummaryService: ReportExecutiveSummaryService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.isLoading = true;
    try {
      this.executiveSummaryText = await this.reportExecutiveSummaryService
        .getExecutiveSummary()
        .toPromise();
      this.isLoading = false;
    } catch (error) {
      console.log(error);
      this.toastr.error("Something went wrong", "Oops!");
      this.isLoading = false;
    }
  }

  async submitUpdate() {
    this.inProgress = true;
    try {
      await this.reportExecutiveSummaryService
        .setExecutiveSummary(this.executiveSummaryText)
        .toPromise();
      this.inProgress = false;
      this.toastr.success("Updated!", "");
    } catch (error) {
      console.log(error);
      this.toastr.error("Something went wrong", "Oops!");
      this.inProgress = false;
    }
  }
}
