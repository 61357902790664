import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import * as uuid from 'uuid';
import { DistributorsService } from 'src/app/services/distributors.service';
import { isItemNotInArray } from 'src/app/global-functions';
import { CategoriesService } from 'src/app/services/categories.service';
import { Category } from 'src/app/interfaces';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductModalComponent implements OnInit {
  isLoadingDistributors = false;
  isLoadingCategories = false;
  isSaveProduct = false;
  productImage;
  productImageName = '';
  distributorName = new FormControl();
  options: any[] = [{ id: 1, name: '22' }, { id: 2, name: '33' }];
  filteredOptions: Observable<any[]>;
  allSelectedProductPreference: any = {};
  productCategory = new FormControl();
  product;
  productName = '';
  shortDescription = '';
  partNumber = '';
  productURL = '';
  msrp: any = 0;
  productAssociations = [];
  allDistributors = [];
  distributorSelected;
  allCategories = [];
  catogorySelected: Category;
  filteredOptionsDistributors: Observable<{}[]>;
  filteredOptionsCategories: Observable<{}[]>;

  constructor(
    public dialogRef: MatDialogRef<ProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastr: ToastrService,
    private distributorsService: DistributorsService,
    private categoriesService: CategoriesService,
    public dialog: MatDialog,
    public productsService: ProductsService
  ) {}

  ngOnInit() {
    this.getDistributors();
    this.getCategories();
    if (this.data && this.data.product) {
      this.product = this.data.product;
      this.prepareData();
    }
  }

  prepareData() {
    // console.warn('product for edit >> ', this.product);
    this.productName = this.product.name;
    this.partNumber = this.product.partNumber;
    this.msrp = this.product.msrp;
    this.productURL = this.product.url;
    this.productImage = this.product.photoData;
    this.productImageName = this.data.product.photo;
    this.shortDescription = this.product.description;

    this.productAssociations = this.product.distributors;

    if (this.product.category && this.product.category.id) {
      this.productCategory.setValue(this.product.category);
      this.isLoadingCategories = true;
      this.categoriesService.getCategory(this.product.category.id).subscribe(
        category => {
          let categoryElement: Category = category.body;
          let keys = Object.keys(this.product.propertyValues);

          categoryElement.properties.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
          categoryElement.properties.forEach(prop => {
            prop.values.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
            if (keys.length > 0 && keys.some(key => +key === +prop.id)) {
              this.allSelectedProductPreference[prop.id] = prop.values.find(val => val.id === this.product.propertyValues[prop.id]);
            } else {
              let NAProp = prop.values.find(i => i.value === 'N/A');
              this.allSelectedProductPreference[prop.id] = NAProp ? NAProp : prop.values[0];
              this.toastr.info('All Product Preferences were set automatically', 'Info');
            }
          });

          this.catogorySelected = categoryElement;
          this.isLoadingCategories = false;
        },
        error => {
          // console.warn('getCategory err ->', error); // example
          this.toastr.error(
            'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
            'Oops!'
          );
          this.isLoadingCategories = false;
        }
      );
    }
  }

  getDistributors() {
    this.isLoadingDistributors = true;
    this.distributorsService.getDistributorsNames().subscribe(
      response => {
        this.allDistributors = response.body;

        this.filteredOptionsDistributors = this.distributorName.valueChanges.pipe(
          startWith(''),
          map((value: any) => (typeof value === 'string' ? value : value.name)),
          map(name => (name ? this._filter(name) : this.allDistributors.slice()))
        );
        this.isLoadingDistributors = false;
      },
      error => {
        // console.warn('getDistributors err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingDistributors = false;
      }
    );
  }

  getCategories() {
    this.isLoadingCategories = true;
    this.categoriesService.getCategoriesNames().subscribe(
      response => {
        this.allCategories = response.body;

        this.filteredOptionsCategories = this.productCategory.valueChanges.pipe(
          startWith(''),
          map((value: any) => (typeof value === 'string' ? value : value.name)),
          map(name => (name ? this._filterCategories(name) : this.allCategories.slice()))
        );
        this.isLoadingCategories = false;
      },
      error => {
        // console.warn('getCategoriesNames err ->', error); // example
        this.toastr.error(
          'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
          'Oops!'
        );
        this.isLoadingCategories = false;
      }
    );
  }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();

    return this.allDistributors.filter(distributor => distributor.name.toLowerCase().includes(filterValue));
  }

  private _filterCategories(value: any): any[] {
    const filterValue = value.toLowerCase();

    return this.allCategories.filter(category => category.name.toLowerCase().includes(filterValue));
  }

  cancel(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event) {
    let file = event.target.files[0];
    if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
      if (file.size < 10000000) {
        const dialogRef = this.dialog.open(ImageCropComponent, {
          width: '94vw',
          maxWidth: '600px',
          maxHeight: '94vh',
          panelClass: 'image-crop-panel',
          data: {
            event
          }
        });

        dialogRef.afterClosed().subscribe(image => {
          if (image) {
            this.productImage = image;
            this.productImageName = file.name;
          }
        });
      } else {
        this.toastr.warning(`Max image size ${Math.round(10000000 / 1024)}kb.`, 'Attention!');
      }
    } else {
      this.toastr.warning('Wrong file type', 'Attention!');
    }
  }

  displayNameFn(item): string | undefined {
    return item ? item.name : undefined;
  }

  distributorAdd() {
    let newDistributor = this.distributorName.value;

    if (newDistributor && newDistributor.id) {
      if (isItemNotInArray(this.productAssociations, newDistributor.id, 'id')) {
        this.productAssociations.push({ id: newDistributor.id, name: newDistributor.name, partNumber: '' });
      } else {
        this.toastr.warning('Distributor already is in the list.', 'Attention!');
      }
    } else {
      this.toastr.warning('Please select Distributor from list.', 'Attention!');
    }
  }

  removeAssociation(distributor) {
    this.productAssociations = this.productAssociations.filter(item => item.id !== distributor.id);
  }

  categoryAdd() {
    let addedCategory = this.productCategory.value;

    // console.warn('addedCategory', addedCategory);
    if (addedCategory && addedCategory.id) {
      if (!this.catogorySelected || addedCategory.id !== this.catogorySelected.id) {
        this.allSelectedProductPreference = {};
        this.isLoadingCategories = true;
        this.categoriesService.getCategory(addedCategory.id).subscribe(
          category => {
            let categoryElement: Category = category.body;

            categoryElement.properties.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));
            categoryElement.properties.forEach(prop => {
              prop.values.sort((a, b) => (a.order !== b.order ? (a.order < b.order ? -1 : 1) : 0));

              let NAProp = prop.values.find(i => i.value === 'N/A');
              this.allSelectedProductPreference[prop.id] = NAProp ? NAProp : prop.values[0];
            });

            this.catogorySelected = categoryElement;
            // console.warn('this.catogorySelected', this.catogorySelected);
            // console.warn('allSelectedProductPreference > ', this.allSelectedProductPreference);

            this.isLoadingCategories = false;
          },
          error => {
            // console.warn('getCategory err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isLoadingCategories = false;
          }
        );
      }
    } else {
      this.catogorySelected = undefined;
      this.toastr.warning('Please select Product Category from list.', 'Attention!');
    }
  }

  productCategorySelected(event) {
    if (this.catogorySelected && event.option && event.option.value && event.option.value.id !== this.catogorySelected.id) {
      this.catogorySelected = undefined;
    }
  }

  changeProductPreference(event, i?) {
    // console.warn('event > ', event);
    // console.warn('i > ', i);
    // console.warn('allSelectedProductPreference > ', this.allSelectedProductPreference);
  }

  clearImage() {
    this.productImage = null;
    this.productImageName = '';
  }

  save() {
    let data: any = {
      categoryId: undefined,
      description: '',
      distributors: [],
      msrp: 0,
      name: '',
      partNumber: '',
      propertyValues: {},
      topSelling: true,
      url: ''
    };

    let errors = [];

    if (!this.catogorySelected && isItemNotInArray(errors, 'Product Category is required.')) {
      errors.push('Product Category is required.');
    }
    if (!this.productName && isItemNotInArray(errors, 'Product Name is required.')) {
      errors.push('Product Name is required.');
    }
    if (!this.partNumber && isItemNotInArray(errors, 'Product Part Number is required.')) {
      errors.push('Product Part Number is required.');
    }
    if (
      this.productAssociations.length > 0 &&
      this.productAssociations.some(item => !item.partNumber) &&
      isItemNotInArray(errors, 'Each Distributor Association should have a part number.')
    ) {
      errors.push('Each Distributor Association should have a part number.');
    }

    if (errors.length > 0) {
      let list = '<ul>';
      errors.forEach(error => {
        list += `<li>${error}</li>`;
      });
      list += '<ul>';

      this.toastr.warning(list, 'Attention!', {
        enableHtml: true,
        timeOut: 4000
      });
    } else {
      data.categoryId = this.catogorySelected.id;
      data.description = this.shortDescription;
      data.msrp = parseFloat(String(this.msrp).replace(',', '.'));
      data.name = this.productName;
      data.partNumber = this.partNumber;
      data.url = this.productURL;

      if (this.productAssociations.length > 0) {
        data.distributors = JSON.parse(JSON.stringify(this.productAssociations)).map(item => {
          delete item.name;
          return item;
        });
      }

      if (this.data && this.data.product) {
        if (this.productImage && this.productImageName) {
          if (this.productImage !== this.data.product.photoData && this.productImageName !== this.data.product.photo) {
            data.photo = {
              media: this.productImage.replace(/data:(image|application)\/(jpeg|png|jpg|json);base64/, ''),
              name: uuid.v4() + '__' + this.productImageName
            };
          } else {
            data.photo = {
              name: this.productImageName
            };
          }
        }
      } else if (this.productImage && this.productImageName) {
        data.photo = {
          media: this.productImage.replace(/data:(image|application)\/(jpeg|png|jpg|json);base64/, ''),
          name: uuid.v4() + '__' + this.productImageName
        };
      }

      if (Object.keys(this.allSelectedProductPreference).length > 0) {
        let keys = Object.keys(this.allSelectedProductPreference);
        keys.forEach(key => {
          data.propertyValues[key] = this.allSelectedProductPreference[key].id;
        });
      }

      // console.warn('data >> ', data);

      this.isSaveProduct = true;
      if (this.data && this.data.product) {
        this.productsService.updateProduct(this.data.product.id, data).subscribe(
          response => {
            // console.log(response);
            this.isSaveProduct = false;
            this.dialogRef.close('success');
          },
          error => {
            // console.warn('updateProduct err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.errors ? error.error.errors.join(', ') : error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isSaveProduct = false;
          }
        );
      } else {
        this.productsService.createProduct(data).subscribe(
          response => {
            // console.log(response);
            this.isSaveProduct = false;
            this.dialogRef.close('success');
          },
          error => {
            // console.warn('createProduct err ->', error); // example
            this.toastr.error(
              'Something went wrong. ' + (error.error && error.error.errors ? error.error.errors.join(', ') : error.error.message ? error.error.message : ''),
              'Oops!'
            );
            this.isSaveProduct = false;
          }
        );
      }
    }
  }
}
