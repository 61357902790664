import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainContentComponent } from './main-content.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { ReportsComponent } from './reports/reports.component';
import { ProductsComponent } from './products/products.component';
import { CategoriesComponent } from './categories/categories.component';
import { ManufacturersComponent } from './manufacturers/manufacturers.component';
import { ImportDataComponent } from './import-data/import-data.component';
import { UsersComponent } from './users/users.component';
import { DistributorsComponent } from './distributors/distributors.component';
import { CodeReferencesComponent } from './code-references/code-references.component';
import { ApplicationUpdatesComponent } from './application-updates/application-updates.component';
import { AgencyManagementComponent } from './agency-management/agency-management.component';
import { ReportExecutiveSummaryComponent } from './report-executive-summary/report-executive-summary.component';
import { RoleGuard } from '../services/role.guard';

const routes: Routes = [
  {
    path: '',
    component: MainContentComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'reports', pathMatch: 'full' },
      {
        path: 'reports',
        component: ReportsComponent,
        data: { roles: ['ADMIN', 'J_MANAGER'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'products',
        component: ProductsComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'manufacturers',
        component: ManufacturersComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'import-data',
        component: ImportDataComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'distributors',
        component: DistributorsComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'code-references',
        component: CodeReferencesComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'application-updates',
        component: ApplicationUpdatesComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'agency-management',
        component: AgencyManagementComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
      {
        path: 'report-executive-summary',
        component: ReportExecutiveSummaryComponent,
        data: { roles: ['ADMIN'] },
        canActivate: [RoleGuard],
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainContentRoutingModule {}
