export const ceateDataFromBlob = (blob: Blob) => {
  let promise = new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        let res = reader.result as string;
        resolve(res);
      },
      false
    );

    if (blob) {
      reader.readAsDataURL(blob);
    }
  });
  return promise;
};

export const isItemNotInArray = (array, item, propArray?, propItem?, toLowerCaseProp?: boolean) => {
  // console.warn('arr', array);
  // console.warn('item', item);
  // console.warn('propArray', propArray);
  // console.warn('propItem', propItem);
  // console.warn('isItemNotInArray result ->', array.findIndex(el => JSON.stringify(propArray ? el[propArray] : el) === JSON.stringify(propItem ? item[propItem] : item)) < 0);
  return (
    array.findIndex(
      el =>
        JSON.stringify(propArray ? (toLowerCaseProp ? String(el[propArray]).toLowerCase() : el[propArray]) : el) ===
        JSON.stringify(propItem ? (toLowerCaseProp ? String(item[propItem]).toLowerCase() : item[propItem]) : item)
    ) < 0
  );
};

export const getTimeZoneOffset = () => {
  let date = new Date();
  let currentTimeZoneOffsetInHours = date.getTimezoneOffset() / 60;
  return currentTimeZoneOffsetInHours * 60 * 60 * 1000;
};

export const logger = (data: string, type?: string, customStyles?: string, otherData?) => {
  const styles = `
    padding: 5px 10px;
    font-size: 12px;
    background: black;
    color: lawngreen;
  `;
  console[type ? type : 'log'](`%c${data}`, customStyles ? styles + customStyles : styles, otherData ? otherData : '');
};

export const getCookie = cookieName => {
  const matches = document.cookie.match(new RegExp('(?:^|; )' + cookieName.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
