import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "../../services/auth-service.service";
import { Router } from "@angular/router";
import { HeaderDataService } from "src/app/services/header-data.service";
import { UserService } from "src/app/services/user.service";
import { Subscription } from "rxjs";

@Component({
  selector: "[app-header]",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public headerTitle = "";
  public userName = "";
  aSub: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private headerData: HeaderDataService,
    private userService: UserService
  ) {
    this.headerData.titleUpdated.subscribe((title) => {
      this.headerTitle = title;
    });
  }

  ngOnInit() {
    this.headerTitle = this.headerData.getTitle();
    this.aSub = this.userService.userChanged$.subscribe((user) => {
      this.userName = user && (user.name || "unregistered");
    });
  }

  logout() {
    this.authService.logout();
  }
}
