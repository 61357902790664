import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BuildService {
  apiLinkProxyDev = '/server/';
  apiLinkProxyProd = '/';
  constructor() {}

  getApiLink() {
    return this.apiLinkProxyProd; //Change Before build
  }
}
