import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'justrite';
  isAlreadyLogined = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.authService.redirectUrl =
      window.location.pathname === '/login'
        ? '/reports'
        : window.location.pathname;

    if (window.localStorage.getItem('SL-UD')) {
      this.isAlreadyLogined = true;
    }
  }

  ngOnInit(): void {
    if (window.location.href.includes('/faq')) {
      this.router.navigate(['/faq']);
    } else if (this.isAlreadyLogined) {
      this.authService.setAuthenticated(true);
      this.router.navigate([this.authService.redirectUrl]);
    } else {
      this.userService.getCurrentUser().subscribe(
        (user) => {
          let authorizedRoles = [
            'A_MANAGER',
            'J_MANAGER',
            'ADMIN'
          ];
          if (user.id && authorizedRoles.some((e) => e == user.role)) {
            this.authService.setAuthenticated(true);
            this.router.navigate([this.authService.redirectUrl]);
          } else {
            this.authService.setAuthenticated(false);
            window.localStorage.removeItem('SL-UD');
            this.router.navigate(['/login']);
          }
          if (!authorizedRoles.some((e) => e == user.role)) {
            this.toastr.warning('Dashboard is for Authorized Users Only');
          }
        },
        (error) => {
          // console.warn('app.comp-init user err -> ', error);
          this.authService.setAuthenticated(false);
          window.localStorage.removeItem('SL-UD');
          this.router.navigate(['/login']);
        }
      );
    }
  }
}
