import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

export interface PromptPopupData {
  title: string;
  message: string;
  inputData: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  maxLength?: number;
}

@Component({
  selector: 'app-prompt-popup',
  templateUrl: './prompt-popup.component.html',
  styleUrls: ['./prompt-popup.component.scss']
})
export class PromptPopupComponent implements OnInit {
  inputData;
  maxLength = 0;
  constructor(
    public dialogRef: MatDialogRef<PromptPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PromptPopupData,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.inputData = this.data.inputData;
    this.maxLength = this.data.maxLength;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    if (!this.inputData || this.inputData.trim().length === 0) {
      this.toastr.warning('The inuput data is incorrect.', 'Attention!');
    } else if (this.maxLength && this.maxLength > 0 && this.inputData.length > 250) {
      this.toastr.warning('Max name length is 250 symbols', 'Attention!');
    } else {
      this.dialogRef.close(this.inputData.trim());
    }
  }
}
