import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BuildService } from './build.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CodeReferenceService {
  page = 0;
  size = 10;
  orderBy = 'name';
  ordering = 'desc';

  constructor(private http: HttpClient, private build: BuildService) {}

  public setPage(page: number) {
    this.page = page;
  }

  public getPage() {
    return this.page;
  }

  public setSize(size: number) {
    this.size = size;
  }

  public getSize() {
    return this.size;
  }

  public setOrderBy(orderBy: string) {
    this.orderBy = orderBy;
  }

  public getOrderBy() {
    return this.orderBy;
  }

  public setOrdering(ordering: string) {
    this.ordering = ordering;
  }

  public getOrdering() {
    return this.ordering;
  }

  getCodeReferences(): Observable<any> {
    return this.http.get(
      this.build.getApiLink() + `web/codeReferences?page=${this.page}&size=${this.size}&orderBy=${this.orderBy}&ordering=${this.ordering}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        observe: 'response'
      }
    );
  }

  getCodeReference(codeReferenceId): Observable<any> {
    return this.http.get(this.build.getApiLink() + `web/codeReferences/${codeReferenceId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  createCodeReference(body): Observable<any> {
    return this.http.post(this.build.getApiLink() + 'web/codeReferences/', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  updateCodeReference(codeReferenceId, body): Observable<any> {
    return this.http.put(this.build.getApiLink() + `web/codeReferences/${codeReferenceId}`, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  deleteCodeReference(codeReferenceId: string | number): Observable<any> {
    return this.http.delete(this.build.getApiLink() + `web/codeReferences/${codeReferenceId}`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      observe: 'response'
    });
  }

  importCodeReferences(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(this.build.getApiLink() + 'web/codeReferences/import', formData, {
      observe: 'response'
    });
  }

  importCodeReferencesProducts(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(this.build.getApiLink() + 'web/codeReferences/importProducts', formData, {
      observe: 'response'
    });
  }

  exportCodeReferences(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/codeReferences/export', {
      observe: 'response',
      responseType: 'blob'
    });
  }

  exportCodeReferencesProducts(): Observable<any> {
    return this.http.get(this.build.getApiLink() + 'web/codeReferences/exportProducts', {
      observe: 'response',
      responseType: 'blob'
    });
  }
}
