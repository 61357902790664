import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth-service.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class LoginErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  justriteLogoSrc = 'assets/images/justrite-logo@2x.png';
  studeLogoSrc = 'assets/images/stude-logo@2x.png';
  title = 'Justrite';
  authError = '';
  loginTitle = 'Please enter your email and password';
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  userLoginFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(this.emailPattern),
  ]);
  passFormControl = new FormControl('', [Validators.required]);
  rememberMeFormControl = new FormControl(true);

  matcher = new LoginErrorStateMatcher();
  copyrightYear = new Date().getFullYear();

  constructor(public authService: AuthService, private router: Router) {}

  login() {
    if (
      this.userLoginFormControl.errors === null &&
      this.passFormControl.errors === null
    ) {
      this.authService.authenticate(
        this.userLoginFormControl.value,
        this.passFormControl.value,
        () => {
          if (this.authService.isAuthenticated()) {
            const redirect = this.authService.redirectUrl
              ? this.authService.redirectUrl
              : '';
            // this.router.navigate([redirect]);
            this.router.navigate(['/']);
          }
        },
        () => {
          this.authError = 'Your email or password is not correct';
        }
      );
    }
  }

  logout() {
    this.authService.logout();
  }

  forgotPassword() {
    window.location.href =
      '//studeapp.justritemfg.com/download/restore-password/';
  }

  goToDownloads() {
    window.location.href = '//studeapp.justritemfg.com/download/';
  }
}
